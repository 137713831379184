
import { Component, Vue, Watch } from 'vue-property-decorator';
import LossSeverityRiskWidget from './widgets/LossSeverityRiskWidget.vue';
import LossSeverityChart from './chart-components/LossSeverityChart.vue';
import PeersAreaChartLegend from '../PeerBenchmarkingArea/PeersAreaChartLegend/PeersAreaChartLegend.vue';
import PeersAreaChart from '../PeerBenchmarkingArea/PeersAreaChart/PeersAreaChart.vue';
import LossSeverityTable from './tables/LossSeverityTable.vue';
import {
  LossSeverityModule,
  LossSeverityRiskGroup,
} from '@/store/modules/company-tabs/loss-severity';
import BreachRatioWidget from './widgets/BreachRatioWidget.vue';
import { BreachRatioModule } from '@/store/modules/company-tabs/breach-ratio';
import LossSeverityTableLight from './tables/LossSeverityTableLight.vue';
import { debounce } from '../../../../utilities/debounce';
import RadioGroup from '@/components/RadioGroup.vue';
import LossCoefficientSelector from './elements/LossCoefficientSelector.vue';
import { getWeightedNpvId } from './utils';
import SeveritySingleRangeSelector from './widgets/SeveritySingleRangeSelector.vue';
import PageWithCompanyName from '@/components/PageWithCompanyName.vue';

@Component({
  components: {
    PageWithCompanyName,
    LossSeverityRiskWidget,
    LossSeverityChart,
    PeersAreaChartLegend,
    PeersAreaChart,
    LossSeverityTable,
    BreachRatioWidget,
    LossSeverityTableLight,
    RadioGroup,
    LossCoefficientSelector,
    SeveritySingleRangeSelector,
  },
})
export default class AdvisoryOverviewPage extends Vue {
  public currentRisk: LossSeverityRiskGroup = LossSeverityModule.currentRiskGroup;
  public severityRange: [number, number] = [
    LossSeverityModule.startRangeInds[0],
    LossSeverityModule.startRangeInds[1],
  ];
  public lossInd = (LossSeverityModule.currentInitialPlot || []).length - 1;

  mounted() {
    this.updateWeightedNpv();
  }

  updateWeightedNpv() {
    const companyId = this.$store.getters.companyId;
    LossSeverityModule.fetchWeightedNpv({
      severityFrom: this.currentPlotData[this.transferableRiskInd[0]].initial.x,
      severityTo: this.currentPlotData[this.transferableRiskInd[1]].initial.x,
      id: getWeightedNpvId(companyId, this.transferableRiskInd),
    });
    LossSeverityModule.fetchWeightedNpv({
      severityFrom: this.currentPlotData[this.retainedRiskInd[0]].initial.x,
      severityTo: this.currentPlotData[this.retainedRiskInd[1]].initial.x,
      id: getWeightedNpvId(companyId, this.retainedRiskInd),
    });
    LossSeverityModule.fetchWeightedNpv({
      severityFrom: this.currentPlotData[this.totalRiskInd[0]].initial.x,
      severityTo: this.currentPlotData[this.totalRiskInd[1]].initial.x,
      id: getWeightedNpvId(companyId, this.totalRiskInd),
    });
  }

  get dr() {
    return debounce(this.updateWeightedNpv, 500);
  }

  @Watch('coefficient')
  @Watch('severityRange')
  onSeverityRangeChanged() {
    this.dr();
  }

  get coefficient() {
    return LossSeverityModule.coefficient;
  }

  get currentPlotData() {
    return LossSeverityModule.currentPlot || [];
  }

  get breachRatioCurrent() {
    return BreachRatioModule.current;
  }

  setCurrentRisk(risk: LossSeverityRiskGroup) {
    this.currentRisk = risk;
  }

  setSeverityRange(range: [number, number]) {
    this.severityRange.splice(0, this.severityRange.length, ...range);
  }

  get companyTitle() {
    return this.$store.getters.companyFullName as string;
  }

  get chartKey() {
    return LossSeverityModule.coefficient;
  }

  get riskList() {
    return Object.values(LossSeverityRiskGroup).map((value) => ({
      value,
      text: value === LossSeverityModule.currentRiskGroup ? value + ' (CURRENT)' : value,
    }));
  }

  get retainedRiskInd(): [number, number] {
    return [0, this.severityRange[0]];
  }

  get transferableRiskInd(): [number, number] {
    return [this.severityRange[0], this.severityRange[1]];
  }

  get totalRiskInd(): [number, number] {
    return [0, this.severityRange[1]];
  }
}
