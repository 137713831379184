
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Chart } from '@/libs/chart-lib';
import { createChart } from './peers-area-chart-config';
import MicroTrendModule from '@/store/modules/company-tabs/micro-trend';
import { ceilUTCDateToTimestamp } from '../../AdvisoryOverview/utils';
import {
  LossSeverityModule,
  LossSeverityRiskGroup,
} from '@/store/modules/company-tabs/loss-severity';

@Component
export default class PeersAreaChart extends Vue {
  @Prop() lossInd!: number;

  private chart?: Chart;
  private updateLabels?: (values: number[]) => void;

  mounted() {
    if (this.trendList.length > 0) {
      const { chart, updateEndLabels } = createChart({
        container: this.$refs.chartContainer as HTMLElement,
        startTimestamp: this.startTimestamp,
        endTimestamp: this.endTimestamp,
        data: this.trendList.filter(
          (el) => el.date.timestamp >= this.startTimestamp && el.date.timestamp <= this.endTimestamp
        ),
      });
      this.chart = chart;
      this.updateLabels = updateEndLabels;
    }
    this.onIndChanged();
  }

  beforeDestroy() {
    delete this.chart;
  }

  activated() {
    window.dispatchEvent(new Event('resize'));
  }

  // store data
  get trendList(): MicroTrendModule['trendList'] {
    return this.$store.getters.microTrendList;
  }

  get endTimestamp() {
    return this.trendList.slice(-1)[0].date.timestamp;
  }

  get startTimestamp() {
    const date = new Date(this.endTimestamp);
    date.setUTCMonth(date.getUTCMonth() - 11);
    return ceilUTCDateToTimestamp(date);
  }

  @Watch('lossInd')
  onIndChanged() {
    const order = [
      LossSeverityRiskGroup.Highest,
      LossSeverityRiskGroup.High,
      LossSeverityRiskGroup['Medium-High'],
      LossSeverityRiskGroup.Medium,
      LossSeverityRiskGroup.Low,
    ];

    const probs = order.map((key) => {
      const plot = LossSeverityModule.plotsInitial[key] || [];
      return plot[this.lossInd].y;
    });

    if (this.updateLabels) this.updateLabels(probs);
  }
}
