
import Skeleton from '@/components/Skeleton.vue';
import {
  LossSeverityRiskGroup,
  LossSeverityModule,
  LossSeverityPlotItem,
  LossSeverityUnit,
} from '@/store/modules/company-tabs/loss-severity';

import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { formatCurrencyV2 } from '../utils';

type TableItem = Record<LossSeverityRiskGroup, LossSeverityPlotItem['y']> &
  Record<'Severity', string>;

@Component({ components: { Skeleton } })
export default class LossSeverityTable extends Vue {
  @Prop() selectedColumn!: LossSeverityRiskGroup;
  @Prop() severityRange!: [number, number];

  mounted() {
    this.updateWeightedNpv();
  }

  updateWeightedNpv() {
    LossSeverityModule.fetchWeightedNpv({
      severityFrom: this.severityList[this.severityRange[0]].initial.x,
      severityTo: this.severityList[this.severityRange[1]].initial.x,
      id: this.weightedNpvId,
    });
  }

  @Watch('severityRange')
  onSeverityRangeChanged() {
    this.updateWeightedNpv();
  }

  get severityList() {
    return LossSeverityModule.currentPlotWithNPV || [];
  }

  get rangeCurrencyAndUnit() {
    return {
      currency: LossSeverityModule.currency,
      unit: this.severityList[this.severityRange[1]].xUnit,
    };
  }

  get tableData() {
    const res: TableItem[] = [];
    const curLength = this.severityList.length;
    for (let i = 0; i < curLength; i++) {
      const item = this.riskList.reduce((pre, risk) => {
        const plotItem = LossSeverityModule.plotsWithNPV[risk.value];
        return { ...pre, [risk.value]: (plotItem && plotItem[i].y) || NaN };
      }, {} as TableItem);
      const sevItem = LossSeverityModule.plotsWithNPV[LossSeverityModule.currentRiskGroup];
      if (sevItem) {
        item.Severity = this.formatCurrency(sevItem[i].xRaw, LossSeverityModule.currency);
      }
      res.push(item);
    }
    return res;
  }

  get riskList() {
    return Object.values(LossSeverityRiskGroup).map((value) => ({
      value,
      text: value === LossSeverityModule.currentRiskGroup ? value + ' (CURRENT)' : value,
    }));
  }

  formatCurrency(value: number, currency?: string) {
    const obj = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: currency || 'EUR',
      maximumFractionDigits: 0,
    });
    return obj.format(value);
  }

  _formatCurrencyV2(value: number, currency?: string, unit?: LossSeverityUnit) {
    return formatCurrencyV2(value, currency, unit);
  }

  get dragAndDropList() {
    return this.tableData.map((_value, index) => ({
      id: index,
      dragIndex: this.severityRange.findIndex((value) => value === index),
    }));
  }

  startDrag(evt: DragEvent, dragIndex: number) {
    const { dataTransfer } = evt;
    if (dataTransfer) {
      dataTransfer.dropEffect = 'move';
      dataTransfer.effectAllowed = 'move';
      dataTransfer.setData('itemID', String(dragIndex));
    }
  }

  onDrop(evt: DragEvent, dropIndex: number) {
    const { dataTransfer } = evt;
    if (dataTransfer) {
      const dragIndex = dataTransfer.getData('itemID');
      if (!this.severityRange.includes(dropIndex)) {
        const newRange = [...this.severityRange];
        newRange[Number(dragIndex)] = dropIndex;
        newRange.sort();
        this.onSeverityRangeChange(newRange as [number, number]);
      }
    }
  }

  @Emit()
  onSeverityRangeChange(newRange: [number, number]) {
    return newRange;
  }

  isRowInRange(ind: number) {
    return ind >= this.severityRange[0] && ind <= this.severityRange[1];
  }

  get weightedNpvId() {
    const companyId = this.$store.getters.companyId;
    return `${companyId}_${this.severityList[this.severityRange[0]].x}_${
      this.severityList[this.severityRange[1]].x
    }`;
  }

  get weightedNpv() {
    return LossSeverityModule.weightedNpvMap.find(({ id }) => id === this.weightedNpvId)?.data;
  }
}
