
import { LossCurrency, LossSeverityUnit } from '@/store/modules/company-tabs/loss-severity';
import { Component, Prop, Vue, ModelSync } from 'vue-property-decorator';
import { getCurrencySymbol, UnitCaptionMap } from '../../AdvisoryOverview/utils';

@Component
export default class SeverityValueInput extends Vue {
  @ModelSync('change', 'valueName', { type: String }) value!: string;
  @Prop() public currency!: LossCurrency;
  @Prop() public unit!: LossSeverityUnit;
  @Prop() public disabled!: boolean;
  @Prop() public isError!: boolean;
  @Prop() public placeholder!: string;

  get currencySymbol() {
    return getCurrencySymbol(this.currency);
  }

  get unitText() {
    return UnitCaptionMap[this.unit];
  }
}
