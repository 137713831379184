
import PageWithCompanyName from '@/components/PageWithCompanyName.vue';
import { LossSeverityModule } from '@/store/modules/company-tabs/loss-severity';
import { Component, Vue } from 'vue-property-decorator';
import { formatCurrencyV2, sumWeightedNPVItems } from '../AdvisoryOverview/utils';
import NpvCalculatorChart from './charts/NpvCalculatorChart.vue';
import NpvCalculatorTable from './tables/NpvCalculatorTable.vue';

const randomColor = require('randomcolor');

@Component({
  components: { PageWithCompanyName, NpvCalculatorChart, NpvCalculatorTable },
})
export default class NpvCalculatorPage extends Vue {
  created() {
    LossSeverityModule.setNpvCalculatorInitial();
  }

  get tableData() {
    return LossSeverityModule.npvCalculatorTableData;
  }

  get maxSeverity() {
    const last = this.tableData[0];
    if (!last) return '—';
    const { attachment, limit } = last;
    return formatCurrencyV2(
      attachment.WeightedNPV + limit.WeightedNPV,
      LossSeverityModule.currency,
      attachment.Unit
    );
  }

  get totalNpv() {
    if (this.tableData.length === 0) return '—';
    let sumNpv = this.tableData[0].npv;
    for (let i = 1; i < this.tableData.length; i++) {
      sumNpv = sumWeightedNPVItems(sumNpv, this.tableData[i].npv);
    }
    return formatCurrencyV2(sumNpv.WeightedNPV, LossSeverityModule.currency, sumNpv.Unit);
  }

  getColor(index: number) {
    return randomColor({
      luminosity: 'light',
      seed: index * 5,
    });
  }

  get legendArr() {
    return [...this.tableData].reverse().map(({ attachment, limit }, ind) => {
      return {
        text: `Layer ${ind + 1}: ${formatCurrencyV2(
          attachment.WeightedNPV,
          LossSeverityModule.currency
        )}–${formatCurrencyV2(attachment.WeightedNPV + limit.WeightedNPV, undefined, limit.Unit)}`,
        color: this.getColor(ind + 1),
      };
    });
  }
}
