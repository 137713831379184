
import { LossSeverityModule } from '@/store/modules/company-tabs/loss-severity';
import { Component, Vue, ModelSync } from 'vue-property-decorator';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import { formatCurrencyV2 } from '../utils';

@Component({
  components: {
    VueSlider,
  },
})
export default class SeveritySingleRangeSelector extends Vue {
  @ModelSync('change', 'ind', { type: Number }) indValue!: number;

  get enabledSeriesRangeData() {
    return (LossSeverityModule.currentInitialPlot || []).map((item) => {
      return {
        x: -item.x,
        y: 0,
        label: formatCurrencyV2(item.x, LossSeverityModule.currency, item.xUnit),
      };
    });
  }

  ttFormatter(ind: number) {
    return this.enabledSeriesRangeData[ind].label;
  }
}
