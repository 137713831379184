import { BreachProbabilityRisk } from '@/store/modules/company-tabs/breach-probability';
import { LossProbabilityOdds } from '@/store/modules/company-tabs/loss-probability';
import {
  getRawValueByUnit,
  LossSeverityRiskGroup,
  LossSeverityUnit,
  lossSeverityUnitOrder,
  WeightedNPVItem,
} from '@/store/modules/company-tabs/loss-severity';
import chroma from 'chroma-js';

const opacityColor = {
  0.5: '80',
  0.4: '66',
  0.3: '4D',
} as const;

export const lossColorScheme: { color: string; opacity?: keyof typeof opacityColor }[] = [
  { color: '#3D894E' },
  { color: '#2D876D', opacity: 0.5 },
  { color: '#25867D', opacity: 0.3 },
  { color: '#1C858D', opacity: 0.4 },
  { color: '#14849C', opacity: 0.5 },
  { color: '#1F75CB', opacity: 0.5 },
  { color: '#2C69F3' },
  { color: '#9486AF', opacity: 0.5 },
  { color: '#EE9E74', opacity: 0.5 },
  { color: '#EC8564', opacity: 0.5 },
  { color: '#EA6D55', opacity: 0.5 },
  { color: '#E85646', opacity: 0.5 },
  { color: '#E42929' },
];

export const breachColorScheme: { color: string; opacity?: keyof typeof opacityColor }[] = [
  { color: '#0D9DB4' },
  { color: '#4ABD82' },
  { color: '#2BB91C' },
  { color: '#93CA44' },
  { color: '#C8B709' },
  { color: '#E0AA35' },
  { color: '#D87313' },
  { color: '#E15D43' },
  { color: '#E31B63' },
];

function getColorWithOpacity(color: string, opacity?: keyof typeof opacityColor) {
  if (opacity) {
    return `${color}${opacityColor[opacity]}`;
  }
  return color;
}

export function getColorByValue(min: number, max: number, value: number, withOpacity?: boolean) {
  const f = chroma
    .scale(
      lossColorScheme.map(({ color, opacity }) =>
        withOpacity ? getColorWithOpacity(color, opacity) : color
      )
    )
    .domain(lossColorScheme.map((_el, ind) => ind));

  let relativeVal;
  if (value < 0) {
    relativeVal = (((lossColorScheme.length - 1) / 2) * Math.abs(min - value)) / Math.abs(min);
  } else {
    relativeVal =
      (lossColorScheme.length - 1) / 2 +
      (((lossColorScheme.length - 1) / 2) * Math.abs(value)) / Math.abs(max);
  }
  return f(relativeVal).hex();
}

export function getColorSchemeValued(min: number, max: number, withOpacity?: boolean) {
  const leftCount = Math.floor(lossColorScheme.length / 2);
  const rightCount = lossColorScheme.length - leftCount;
  const leftPartColors = lossColorScheme.slice(0, leftCount);
  const minHalfArr = leftPartColors.map(({ color, opacity }, ind) => {
    return {
      value: min + ((Math.abs(min) - 1) / leftCount) * ind,
      color: withOpacity ? getColorWithOpacity(color, opacity) : color,
    };
  });
  const rightPartColors = lossColorScheme.slice(-rightCount);
  const maxHalfArr = rightPartColors.map(({ color, opacity }, ind) => {
    return {
      value: 1 + ((Math.abs(max) - 1) / (rightCount - 1)) * ind,
      color: withOpacity ? getColorWithOpacity(color, opacity) : color,
    };
  });
  return [...minHalfArr, ...maxHalfArr];
}

export function getColorByClosestValue(
  min: number,
  max: number,
  targetValue: number,
  withOpacity?: boolean
): string {
  const arr = getColorSchemeValued(min, max, withOpacity);
  const dif = arr.map(({ value }) => Math.abs(value - targetValue));
  const minInd = dif.indexOf(Math.min(...dif));
  return arr[minInd].color;
}

export function ceilDate(date: Date): Date {
  const dateLeft = new Date(date.getFullYear(), date.getMonth(), 1);
  const dateRight = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  if (date.getDate() === dateLeft.getDate()) return dateLeft;
  return dateRight;
}

export function ceilUTCDateToTimestamp(date: Date) {
  const timestampLeft = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1);
  const timestampRight = Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 1);
  if (date.getUTCDate() <= 1) return timestampLeft;
  return timestampRight;
}

export function roundDayDate(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function roundDayDateWithOffset(date: Date): Date {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffset);
}

export function getMonthDayLabelText(date: Date): string {
  return date.toLocaleString('en-GB', { day: 'numeric', month: 'long' });
}

const getMid = (from: number, to: number, pos: number) => from + (to - from) * pos;

export function getColorSchemeValued_Breach(withOpacity?: boolean) {
  const breachValues = [
    [0.12, 13.219],
    [getMid(0.12, 0.14, 0.5), getMid(13.219, 36.353, 0.5)],
    [0.14, 36.353],
    [getMid(0.14, 0.22, 0.5), getMid(36.353, 59.486, 0.5)],
    [0.22, 59.486],
    [getMid(0.22, 1.56, 0.5), getMid(59.486, 82.619, 0.5)],
    [1.56, 82.619],
    [getMid(1.56, 2.86, 0.5), getMid(82.619, 105.753, 0.5)],
    [2.86, 105.753],
  ];
  return breachColorScheme.map(({ color, opacity }, ind) => {
    return {
      value: breachValues[ind][0],
      size: breachValues[ind][1],
      color: withOpacity ? getColorWithOpacity(color, opacity) : color,
    };
  });
}

export function getColorByClosestValue_Breach(targetValue: number, withOpacity?: boolean): string {
  const arr = getColorSchemeValued_Breach(withOpacity);
  const dif = arr.map(({ value }) => Math.abs(value - targetValue));
  const minInd = dif.indexOf(Math.min(...dif));
  return arr[minInd].color;
}

export const LossProbabilityColorScheme: Record<LossProbabilityOdds, string> = {
  MinOdds: '#3D894E',
  OneOdds: '#2D876D',
  SecondOdds: '#25867D',
  ThreeOdds: '#1C858D',
  FourOdds: '#14849C',
  FiveOdds: '#1F75CB',
  SixOdds: '#2C69F3',
  SevenOdds: '#9486AF',
  EightOdds: '#EE9E74',
  NineOdds: '#EC8564',
  TenOdds: '#EA6D55',
  ElevenOdds: '#E85646',
  MaxOdds: '#E42929',
};

export const LossProbabilityHasLabelScheme: Record<LossProbabilityOdds, boolean> = {
  MinOdds: true,
  OneOdds: false,
  SecondOdds: true,
  ThreeOdds: false,
  FourOdds: true,
  FiveOdds: false,
  SixOdds: false,
  SevenOdds: false,
  EightOdds: true,
  NineOdds: false,
  TenOdds: true,
  ElevenOdds: false,
  MaxOdds: true,
};

export const BreachProbabilityTextScheme: Record<BreachProbabilityRisk, string> = {
  LowRiskProb: 'LOW',
  LowMediumRiskProb: '',
  MediumRiskProb: 'MEDIUM',
  MediumMediumHighRiskProb: '',
  MediumHighRiskProb: 'MEDIUM/HIGH',
  MediumHighHighRiskProb: '',
  HighRiskProb: 'HIGH',
  HighHighestRiskProb: '',
  HighestRiskProb: 'HIGHEST',
};

export const BreachProbabilityColorScheme: Record<BreachProbabilityRisk, string> = {
  LowRiskProb: '#0D9DB4',
  LowMediumRiskProb: '#4ABD82',
  MediumRiskProb: '#2BB91C',
  MediumMediumHighRiskProb: '#93CA44',
  MediumHighRiskProb: '#C8B709',
  MediumHighHighRiskProb: '#E0AA35',
  HighRiskProb: '#D87313',
  HighHighestRiskProb: '#E15D43',
  HighestRiskProb: '#E31B63',
};

export const BreachProbabilityBarSizeScheme: Record<BreachProbabilityRisk, number> = {
  LowRiskProb: 13.219,
  LowMediumRiskProb: getMid(13.219, 36.353, 0.5),
  MediumRiskProb: 36.353,
  MediumMediumHighRiskProb: getMid(36.353, 59.486, 0.5),
  MediumHighRiskProb: 59.486,
  MediumHighHighRiskProb: getMid(59.486, 82.619, 0.5),
  HighRiskProb: 82.619,
  HighHighestRiskProb: getMid(82.619, 105.753, 0.5),
  HighestRiskProb: 105.753,
};

export const LossSeverityColorScheme: Record<LossSeverityRiskGroup, string> = {
  [LossSeverityRiskGroup.Low]: '#049AB2',
  [LossSeverityRiskGroup.Medium]: '#2FC420',
  [LossSeverityRiskGroup['Medium-High']]: '#D5C307',
  [LossSeverityRiskGroup.High]: '#DE822B',
  [LossSeverityRiskGroup.Highest]: '#E31B1B',
};

export const LossSeverityBgColorScheme: Record<LossSeverityRiskGroup, string> = {
  [LossSeverityRiskGroup.Low]: '#d5ebef',
  [LossSeverityRiskGroup.Medium]: '#d9f4d6',
  [LossSeverityRiskGroup['Medium-High']]: '#f5f3d3',
  [LossSeverityRiskGroup.High]: '#f8e7da',
  [LossSeverityRiskGroup.Highest]: '#fad6d7',
};

export const UnitCaptionMap: Record<LossSeverityUnit, string> = {
  [LossSeverityUnit.bln]: 'B',
  [LossSeverityUnit.mln]: 'MN',
  [LossSeverityUnit.k]: 'K',
  [LossSeverityUnit.none]: '',
};

export function formatCurrencyV2(value: number, currency?: string, unit?: LossSeverityUnit) {
  let obj;
  if ((value | 0) < value) {
    obj = new Intl.NumberFormat('de-DE', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      style: currency ? 'currency' : 'decimal',
      currency,
    });
  } else {
    obj = new Intl.NumberFormat('de-DE', {
      maximumFractionDigits: 0,
      style: currency ? 'currency' : 'decimal',
      currency,
    });
  }
  const arr = obj.format(value).split(/(\s+)/);
  const leg = unit ? UnitCaptionMap[unit] : '';
  return (arr[2] || '') + arr[0] + leg;
}

export function formatNumber(value: number) {
  let obj;
  if ((value | 0) < value) {
    obj = new Intl.NumberFormat('de-DE', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    });
  } else {
    obj = new Intl.NumberFormat('de-DE', {
      maximumFractionDigits: 0,
    });
  }
  return obj.format(value);
}

export function getCurrencySymbol(currency?: string) {
  return (0)
    .toLocaleString('de-DE', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}

export function getWeightedNpvId(company: string, range: [number, number]) {
  return `${company}_${range[0]}_${range[1]}`;
}

export function sumWeightedNPVItems(
  a: WeightedNPVItem,
  b: WeightedNPVItem,
  negative?: boolean
): WeightedNPVItem {
  const unit =
    lossSeverityUnitOrder.find((unit) => unit === a.Unit || unit === b.Unit) ||
    LossSeverityUnit.none;
  const rawSum = negative
    ? a.WeightedNPVRaw - b.WeightedNPVRaw
    : a.WeightedNPVRaw + b.WeightedNPVRaw;
  const del = getRawValueByUnit(1, unit);
  return {
    Unit: unit,
    WeightedNPVRaw: rawSum,
    WeightedNPV: rawSum / del,
  };
}
