
import { Component, Vue } from 'vue-property-decorator';
import { EarlyWarningModule } from '@/store/modules/company-tabs/early-warning';
import CollapseContent from './elements/CollapseContent.vue';
import EarlyWarningDashboard from './elements/EarlyWarningDashboard.vue';
import PageWithCompanyName from '@/components/PageWithCompanyName.vue';

@Component({
  components: {
    PageWithCompanyName,
    CollapseContent,
    EarlyWarningDashboard,
  },
})
export default class EarlyWarningPage extends Vue {
  get yearList() {
    return Object.entries(EarlyWarningModule.sectorMap).reverse();
  }
}
