import { Chart, Ticks } from '@/libs/chart-lib/index';
import { MicroTrendItem } from '@/store/modules/company-tabs/micro-trend';

const canvasPaddings = [44, 75, 44, 75];
const CLIP_RADIUS = 12;

interface ChartData {
  container: HTMLElement;
  startTimestamp: number;
  endTimestamp: number;
  data: MicroTrendItem[];
}

export function createChart({ container, startTimestamp, endTimestamp, data }: ChartData) {
  const chart: Chart = new Chart(container, [startTimestamp, endTimestamp], [0, 1]);

  const { xAxis, yAxis } = chart;
  const LABELS_COLOR = '#000000';

  // BOTTOM MONTH TICK
  xAxis.display = false;
  xAxis.ticks.label.setOptions(true, LABELS_COLOR, 'bottom', 12, ['12', 'JetBrains Mono']);
  xAxis.ticks.setOptions(false, 'timestampMonthStart');
  xAxis.grid.setOptions(true, `${LABELS_COLOR}26`, 1, [1, 0]);

  // BOTTOM YEAR TICK
  const BottomYearTick = new Ticks(chart.xAxis.type);
  BottomYearTick.setOptions(false, 'timestampMonthStart_year');
  BottomYearTick.label.setOptions(true, LABELS_COLOR, 'bottom', 12 + 16, ['12', 'JetBrains Mono']);
  xAxis.addCustomTicks(BottomYearTick);

  // TOP YEAR TICK
  const TopYearTick = new Ticks(chart.xAxis.type);
  TopYearTick.setOptions(false, 'timestampMonthStart_year');
  const xCanvasHeight = xAxis.canvas.viewport.height;
  TopYearTick.label.setOptions(true, LABELS_COLOR, 'top', xCanvasHeight + 16 + 7, [
    '12',
    'JetBrains Mono',
  ]);
  xAxis.addCustomTicks(TopYearTick);

  // TOP MONTH TICK
  const TopMonthTick = new Ticks(chart.xAxis.type);
  TopMonthTick.setOptions(false, 'timestampMonthStart');
  TopMonthTick.label.setOptions(true, LABELS_COLOR, 'top', xCanvasHeight + 16 + 7 + 16, [
    '12',
    'JetBrains Mono',
  ]);
  xAxis.addCustomTicks(TopMonthTick);
  xAxis.setClipRadius(CLIP_RADIUS);

  // Y AXIS
  yAxis.display = false;
  yAxis.ticks.label.setOptions(false);

  // SERIES COMMON
  const xTimestampList = data.map(({ date }) => date.timestamp);

  // SERIES AREA
  const lowHB = data.map(({ LowToMediumRiskSplit }) => LowToMediumRiskSplit);
  const lowLB = data.map(({ LowRiskLowerBound }) => LowRiskLowerBound);
  const mediumHB = data.map(({ MediumToMediumHighRiskSplit }) => MediumToMediumHighRiskSplit);
  const mediumLB = data.map(({ LowToMediumRiskSplit }) => LowToMediumRiskSplit);
  const medium_highHB = data.map(({ MediumHighToHighRiskSplit }) => MediumHighToHighRiskSplit);
  const medium_highLB = data.map(({ MediumToMediumHighRiskSplit }) => MediumToMediumHighRiskSplit);
  const highHB = data.map(({ HighToHighestRiskSplit }) => HighToHighestRiskSplit);
  const highLB = data.map(({ MediumHighToHighRiskSplit }) => MediumHighToHighRiskSplit);
  const highestHB = data.map(({ HighestRiskHigherBound }) => HighestRiskHigherBound);
  const highestLB = data.map(({ HighToHighestRiskSplit }) => HighToHighestRiskSplit);

  // AREA CHARTS
  const configArea = (
    name: string,
    topArr: number[],
    botArr: number[],
    lineColor: string,
    brushColor: string
  ) => {
    const PLOT_NAME = `${name}_AREA`;
    chart.addPlot(PLOT_NAME, 'area', 1, lineColor, brushColor);
    chart
      .addSeries(PLOT_NAME, [
        [...xTimestampList, ...[...xTimestampList].reverse()],
        [...topArr, ...[...botArr].reverse()],
      ])
      .setClipRadius(CLIP_RADIUS)
      .setPlotsIds(PLOT_NAME);
  };

  configArea('LOW', lowHB, lowLB, `${LABELS_COLOR}26`, `#77b3dc${'80'}`);
  configArea('MEDIUM', mediumHB, mediumLB, `${LABELS_COLOR}26`, `#4ccb41${'80'}`);
  configArea(
    'MEDIUM-HIGH',
    medium_highHB,
    medium_highLB,
    `${LABELS_COLOR}${'26'}`,
    `#e9d933${'80'}`
  );
  configArea('HIGH', highHB, highLB, `${LABELS_COLOR}26`, `#fa8719${'80'}`);
  configArea('HIGHEST', highestHB, highestLB, `${LABELS_COLOR}26`, `#ed1918${'80'}`);

  // SERIES LINE
  const lowMedianList = data.map(({ LowRiskMedian }) => LowRiskMedian);
  const mediumMedianList = data.map(({ MediumRiskMedian }) => MediumRiskMedian);
  const mediumHighMedianList = data.map(({ MediumHighRiskMedian }) => MediumHighRiskMedian);
  const highMedianList = data.map(({ HighRiskMedian }) => HighRiskMedian);
  const highestMedianList = data.map(({ HighestRiskMedian }) => HighestRiskMedian);

  // LINE CHARTS
  const configRiskLine = (name: string, arr: number[], lineColor: string) => {
    chart.addPlot(`${name}_LINE`, 'line', 2, lineColor, [2, 3]);
    chart.addSeries(`${name}_LINE`, [xTimestampList, arr]).setPlotsIds(`${name}_LINE`);
  };

  configRiskLine('LOW', lowMedianList, `#1985B3${'80'}`);
  configRiskLine('MEDIUM', mediumMedianList, `#0D9B00${'80'}`);
  configRiskLine('MEDIUM-HIGH', mediumHighMedianList, `#D8B810`);
  configRiskLine('HIGH', highMedianList, `#CE6809${'80'}`);
  configRiskLine('HIGHEST', highestMedianList, `#D91111${'80'}`);

  // SIDE LABELS
  const sideLabels = [
    {
      text: 'HIGHEST',
      color: '#E31B1B',
      end: highestMedianList.slice(-1),
      start: highestMedianList.slice(0, 1),
      breachProb: data.slice(-1)[0].HighestRiskBreachProbability,
      count: data.slice(-1)[0].HighestRiskCount,
    },
    {
      text: 'HIGH',
      color: '#E16C00',
      end: highMedianList.slice(-1),
      start: highMedianList.slice(0, 1),
      breachProb: data.slice(-1)[0].HighRiskBreachProbability,
      count: data.slice(-1)[0].HighRiskCount,
    },
    {
      text: 'MEDIUM/\nHIGH',
      color: '#E2B642',
      end: mediumHighMedianList.slice(-1),
      start: mediumHighMedianList.slice(0, 1),
      breachProb: data.slice(-1)[0].MediumHighRiskBreachProbability,
      count: data.slice(-1)[0].MediumHighRiskCount,
    },
    {
      text: 'MEDIUM',
      color: '#2BB91C',
      end: mediumMedianList.slice(-1),
      start: mediumMedianList.slice(0, 1),
      breachProb: data.slice(-1)[0].MediumRiskBreachProbability,
      count: data.slice(-1)[0].MediumRiskCount,
    },
    {
      text: 'LOW',
      color: '#0D9CB4',
      end: lowMedianList.slice(-1),
      start: lowMedianList.slice(0, 1),
      breachProb: data.slice(-1)[0].LowRiskBreachProbability,
      count: data.slice(-1)[0].LowRiskCount,
    },
  ];

  const sideLabelsEndSort = [...sideLabels];
  const sideLabelsStartSort = [...sideLabels.sort((a, b) => b.start[0] - a.start[0])];

  chart
    .addPlot('LINE_END_LABELS', 'side_text_end')
    .label.setOptions(
      true,
      LABELS_COLOR,
      'right',
      13.54,
      [13, 'JetBrains Mono', '400'],
      sideLabelsEndSort.map(({ color }) => color)
    )
    .setCenterX(0);

  const probabilitySeries = chart
    .addSeries(
      'LINE_END_LABELS',
      [sideLabelsEndSort.map(() => endTimestamp), sideLabelsEndSort.map(({ end }) => end[0])],
      sideLabelsEndSort.map(({ breachProb }) => String(breachProb) + '%')
    )
    .setPlotsIds('LINE_END_LABELS');

  const updateEndLabels = (newValues: number[]) =>
    probabilitySeries.replaceSeriesData(
      [sideLabelsEndSort.map(() => endTimestamp), sideLabelsEndSort.map(({ end }) => end[0])],
      true,
      newValues.map((val) => String(val) + '%')
    );

  chart
    .addPlot('LINE_START_LABELS', 'side_text_start')
    .label.setOptions(
      true,
      LABELS_COLOR,
      'left',
      13.54,
      [13, 'JetBrains Mono', '400'],
      sideLabelsStartSort.map(({ color }) => color)
    )
    .setCenterX(-1);
  chart
    .addSeries(
      'LINE_START_LABELS',
      [
        sideLabelsStartSort.map(() => startTimestamp),
        sideLabelsStartSort.map(({ start }) => start[0]),
      ],
      sideLabelsStartSort.map(({ count }) => String(count))
    )
    .setPlotsIds('LINE_START_LABELS');

  // COMPANY
  const companyList = data.map(({ RelativeRank }) => RelativeRank);
  chart.addPlot(`COMPANY_LINE`, 'line', 2, LABELS_COLOR, [2, 3]);
  chart.addSeries(`COMPANY_LINE`, [xTimestampList, companyList]).setPlotsIds(`COMPANY_LINE`);

  // TRENDS
  const [up, mid, down] = data.reduce(
    (pre, { Trend, RelativeRank }, ind) => {
      const [up, mid, down] = pre;
      const angle = Trend * 45;
      const item = {
        y: RelativeRank,
        x: xTimestampList[ind],
        angle,
      };
      if (angle <= -5) {
        up.push(item);
      } else if (angle < 5) {
        mid.push(item);
      } else {
        down.push(item);
      }
      return [up, mid, down];
    },
    [[], [], []] as Array<{ x: number; y: number; angle: number }[]>
  );

  // CIRCLE & ARROW CHARTS
  const configTrendCircle = (
    name: string,
    [x, y]: [number[], number[]],
    angleArr: number[],
    outlineColor: string,
    color: string
  ) => {
    chart.addPlot(`${name}_CIRCLE`, 'circle', 8, outlineColor, color, 4.5);
    chart.addPlot(`${name}_ARROW`, 'arrows_with_angle').setOptionsPartially({
      lineColor: color,
      lineWidth: 2,
      mainSize: 17,
      offset: { x: 0, y: -17 },
      lineJoin: 'round',
    });
    chart
      .addSeries(
        `${name}_CIRCLE`,
        [x, y],
        [],
        angleArr.map((angle) => ({ angle }))
      )
      .setPlotsIds(`${name}_CIRCLE`, `${name}_ARROW`);
  };
  configTrendCircle(
    'UP',
    [up.map(({ x }) => x), up.map(({ y }) => y)],
    up.map(({ angle }) => angle),
    `#ff0000${'1F'}`,
    '#E42929'
  );
  configTrendCircle(
    'MID',
    [mid.map(({ x }) => x), mid.map(({ y }) => y)],
    mid.map(({ angle }) => angle),
    `#D18007${'3D'}`,
    '#D18007'
  );
  configTrendCircle(
    'DOWN',
    [down.map(({ x }) => x), down.map(({ y }) => y)],
    down.map(({ angle }) => angle),
    `#3D894C${'36'}`,
    '#3D894C'
  );

  // CHART INIT
  chart.setCanvasPaddings(...canvasPaddings);
  chart.refresh();
  return { chart, updateEndLabels };
}
